import React from "react";
import "./UnderConstruction.css";

const UnderConstruction = () => {
    return (
        <div className="under-construction">
            <h1 className="glitch">Under Construction</h1>
            <p>We're working on something awesome. Stay tuned!</p>
            <div className="loading-bar"></div>
        </div>
    );
};

export default UnderConstruction;
